import React, { useState } from 'react';
import {
  Grid,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const AddToListComponent = ({validateFunction, helperText, guideText, items, setItems}) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const handleAddItem = () => {
    if (inputValue === undefined || inputValue.trim() === '')
    {
      setError(false);
      return;
    }

    if (!validateFunction(inputValue.trim()))
    {
      setError(true);
      return;
    }

    setError(false);
    if (inputValue.trim() !== '') {
      setItems((prevItems) => [...prevItems, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (index) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Trigger the button click event
      handleAddItem();
    }
  };

  return (
    <div>
      <TextField
        fullWidth
        label="Add Item"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleEnterKeyPress}
        InputProps={{
          endAdornment: (
            <IconButton color="primary" onClick={handleAddItem}>
              <AddIcon />
            </IconButton>
          ),
        }}
        helperText={error ? helperText : guideText}
        error={error}
      />
      <List>
      <Grid container spacing={1}>
  {items.map((item, index) => (
    <Grid item key={index}>
      <ListItem sx={{ backgroundColor: '#f0f0f0', borderRadius: 4 }}>
        <ListItemText primary={item} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => handleRemoveItem(index)}>
            <CloseIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Grid>
  ))}
</Grid>
</List>
    </div>
  );
};

export default AddToListComponent;
