import React from 'react';
import { Paper , Stack, Typography, Grid } from '@mui/material';

const UserInfoPaper = ({ labels, values }) => {
  return (
    <Paper elevation={0} style={{ padding: '16px', width: '100%' }}>
      <Stack spacing={2}>
        {labels.map((label, index) => (
          <Stack key={index}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {label}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {values[index]}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
};

export default UserInfoPaper;
