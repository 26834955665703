import React from 'react'
import LoadingCircle from '../dashboardComponents/LoadingCircle';
import {
  Card,
  CardHeader,
  Divider,
  Stack ,
  CardContent,
} from "@mui/material";
import SettingsCard from './SettingComponnent/SettingsCard';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "../../projectAxios";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const isValidDomain = require('is-valid-domain')


const RESTRICTION_CHANGE = 1;
const ENCRYPTION_CHANGE = 2;


const ParseIfNotNull = (obj) =>
{
  if (obj !== null && obj !== undefined)
  {
    return JSON.parse(obj.data);
  }

  return null;
}


const linkValidationFunction = (link) =>
{
  try 
  { 
    const newUrl = new URL(link); 
    return (newUrl.protocol === 'http:' || newUrl.protocol === 'https:') &&
    newUrl.host.includes(".") && 
    !newUrl.host.startsWith(".") &&
    !newUrl.host.endsWith(".") &&
    newUrl.origin === link && 
    isValidDomain(newUrl.host);
  } catch (err) {
      return false;
  }
}


const restrictionToArray = (restriction) =>
{
  if (restriction === null || restriction === undefined || restriction === '')
  {
    return [];
  }

  if (restriction.includes(",") === false)
  {
    return [restriction];
  }

  return restriction.split(",");
}

const encryptionToBoolean = (encryption) => 
{
  if (encryption === null || encryption === undefined)
  {
    return false;
  }

  if (encryption === true || encryption === false)
  {
    return encryption;
  }

  return encryption.toLowerCase() === "true";
}

// 3 options:
// "freeGigaStorage":10,"freeGigaUsage":10,"planPrice":20,"pricePerGigaBendwidth":0.05,"pricePerGigaStorage":0.025,"id":"20db9ccd447f48d49d4f62f5e7ddb7e7","firstName":"Meir","lastName":"asfasf","capabilities":"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16","email":"aaa@aaa.com","isActive":true,"restriction":null,"encryption":"False"
const SecuritySettings = ({userInformation, isLoading, token, revokeUserInformation}) => {
  const [openDialog, setOpenDialog] = React.useState(undefined);
  const [warningTitle, setWarningTitle] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);
  const [showResponseToUser, setShowResponseToUser] = React.useState(false);
  const [responseType, setResponseType] = React.useState("error");
  const [serverResponse, setServerResponse] = React.useState("Exmple");

  const obj = ParseIfNotNull(userInformation); 
    

  const handleClose = async (typeOfClose) =>
  {
    const op = openDialog;
    setOpenDialog(undefined);
    if (typeOfClose !== true)
    {
      // Do nothing
      return;
    }

    // continue with the requested action - update the window
    //console.log("Do action as user wants: ", op);


    try {
      // Show loading screen
      setShowLoading(true);

      // Send Axios request
      const response = await axios.post(
        '/UserInformation',  // Replace with your actual API endpoint
        `fieldName=${op.field}&fieldValue=${op.value}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authenticate: token
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        // Display success message
        setServerResponse('Update successful');
        setResponseType("success");
        setShowResponseToUser(true);

        // Make sure we reload the page
        //console.log("Try trigger revokeUserInformation");
        revokeUserInformation();
      } else {
        // Display error message for non-successful response
        setServerResponse('Error updating information: ' + response.status);
        setResponseType("error");
        setShowResponseToUser(true);
      }
    } catch (error) {

      setServerResponse('Error updating information');
      setResponseType("error");
      setShowResponseToUser(true);

    } finally {
      // Hide loading screen
      setShowLoading(false);
    }
  }

  const dialog = (<Dialog
    open={openDialog !== undefined}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {warningTitle}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      The action you are about to take could impact your users.
      Are you sure you want to proceed?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} autoFocus>Cancel</Button>
      <Button onClick={() => { handleClose(true); }}>Continue</Button>
    </DialogActions>
  </Dialog>);



  const handleDomainUpdateClick = (val, type) =>
  {
    //console.log(val);
    if (val.isValid !== true)
    {
      return;
    }

    // Anywhere == 0 => send empty option dialog
    //selectOption === 0

    // Our list of values 
    //multipleValues

    
    switch (type) {
      case RESTRICTION_CHANGE:
        setWarningTitle("Modification of Domain Restriction Settings"); 
        setOpenDialog({
          field: "restriction",
          value: val.selectOption === 0 ? "" : val.multipleValues.join(',')
        });
        break;
      case ENCRYPTION_CHANGE:
        setWarningTitle("Modification of Encryption Settings Settings"); 
        setOpenDialog({
          field: "encryption",
          value: val.booleanOption
        });
        break;
      default:
        break;
    };
  }


  const cardContent = (obj === null ? null : <CardContent >
    <Stack spacing={1}>

    <SettingsCard 
      title={"Domain Restriction"}
      description={"By using Domain Restrictions, you can specify which domains and subdomains are allowed to stream your videos. With this feature, unauthorized individuals cannot access your video stream links or view your content from unapproved domains even if they obtain your video stream links. You can leave the domain field blank or set it to 'Anyware' to disable restrictions, or you can enter a list of domains."}
      //stringOption={""}
      label={"Enter allowed domain"}
      onUpdate={(val) => { handleDomainUpdateClick(val, RESTRICTION_CHANGE); }}
      checkFunc={linkValidationFunction}
      helperText={"Invalid URL. Please enter a valid HTTP or HTTPS domain"}
      allowList={true}
      allowEmpty={true}
      select={true}
      selectFields={["Anywhere", "Specific domains"]}
      currentInfo={restrictionToArray(obj.restriction)}
      visibleWhenSelected={1}
      guideText={"Example: http://www.example.com"}
      shouldSelectedBeVisible={restrictionToArray(obj.restriction).length > 0}
    />
    
    <Divider style={{color:"#FAFAFA"}}/>
    
    <SettingsCard 
      title={"Encryption Settings"}
      description={"This setting will exclusively affect videos uploaded from this point forward. By encrypting each video stream, it enhances security and makes piracy more difficult. It is important to note that this may result in slight increases in bandwidth costs and viewer CPU overhead, which may slow down and buffer video on some devices. We utilize AES-128 for encryption."}          
      booleanOption={true}
      currentInfo={encryptionToBoolean(obj.encryption)}
      onUpdate={(val) => { handleDomainUpdateClick(val, ENCRYPTION_CHANGE); }}
    />

    
    <Snackbar
      open={showResponseToUser}
      autoHideDuration={2000} // 2 seconds
      onClose={() => setShowResponseToUser(false)}
      size="lg"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ flexDirection: 'column', gap: 1, '--Button-gap': 0 }}
    >
      <Alert onClose={() => setShowResponseToUser(false)} severity={responseType} sx={{ width: '100%' }}>
       {serverResponse}
      </Alert>
    </Snackbar>

    {dialog}
    </Stack>
  </CardContent>);


  return (
    <Card
      style={{
        height: "100%",
        position: "relative",
        //backgroundColor: "#E3F2FD",
      }}
    >
      <CardHeader
        title="Security"
        style={{ textAlign: "center", color:"#757575"}}/>

        {isLoading === true || showLoading === true ? <LoadingCircle/> : cardContent}
      
    </Card>
    )
}

export default SecuritySettings;
