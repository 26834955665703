import React, { useState } from 'react';
import { Button, Switch, Paper , Stack, Typography, TextField, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import AddToListComponent from "./AddToListComponent";
//import Button from '@mui/material-next/Button';
//import Switch from '@mui/material-next/Switch';

const SettingsCard = ({ title, description, booleanOption, stringOption, onUpdate, label, checkFunc, helperText, allowList, allowEmpty, select, selectFields, visibleWhenSelected, guideText, currentInfo, shouldSelectedBeVisible }) => {
  const [isBooleanOptionEnabled, setIsBooleanOptionEnabled] = useState(booleanOption === true ?  currentInfo: false);
  const [stringOptionValue, setStringOptionValue] = useState(stringOption || '');
  const [error, setError] = useState(false);
  const [selectValue, setSelectValue] = React.useState(shouldSelectedBeVisible === true ? visibleWhenSelected : 0);
  const [toShowListSelector, setToShowListSelector] = useState(visibleWhenSelected === selectValue);
  const [items, setItems] = useState(currentInfo);

  const handleBooleanOptionChange = () => {
    setIsBooleanOptionEnabled((prev) => !prev);
  };


  const isValidStrValue =(inputValue)=>
  {
    // expect list of saparate ,
    if (allowList == true && inputValue.includes(','))
    {
      var isAllValid = true;
      inputValue.split(",").forEach(domain => {
        isAllValid = isAllValid && checkFunc(domain);
      });

      return isAllValid;
    }

    // Check if the input matches the URL pattern
    console.log(checkFunc(inputValue), inputValue)
    return checkFunc(inputValue);
  }

  const handleStringOptionChange = (event) => {
    const inputValue = event.target.value;
    setStringOptionValue(inputValue);

    console.log(checkFunc)
    if (checkFunc === undefined)
    {
      return;
    }

    if (inputValue === '' || inputValue === undefined)
    {
      setError(false);
      return;
    }

    // Check if the input matches the URL pattern
    console.log(checkFunc(inputValue), inputValue)
    if (isValidStrValue(inputValue)) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const isValid = () =>
  {
    // We in select + multiple status
    if (select === true && allowList === true)
    {
        // If we in the choosing part - don't allow empty
        if (selectValue === visibleWhenSelected)
        {
            // We excpect here at least 1 value
            //return items.length > 0;
            return true; // allow alwise the user to save change
        }

        // else, empty is fine as well
        return true;
    }

    // This is alwise valid
    if (booleanOption !== undefined && stringOption === undefined)
    {
      return true;
    }

    // String option
    if (stringOption !== undefined)
    {
      if ( allowEmpty === true && (stringOptionValue === '' || stringOptionValue === undefined))
      {
        return true;
      }
      
      if (stringOptionValue !== '' && stringOptionValue !== undefined && isValidStrValue(stringOptionValue))
      {
        return true;
      }
    }

    return false;
  }

  const handleUpdateClick = () => {
    onUpdate({
      booleanOption: isBooleanOptionEnabled,
      stringOption: stringOptionValue,
      selectOption: selectValue,
      multipleValues: items,
      isValid: isValid(),
    });
  };

  const handleSelectChange = (event) =>
  {
    setToShowListSelector(event.target.value === visibleWhenSelected);
    setSelectValue(event.target.value);
  }

  return (
    <Paper elevation={0}>
      <Grid container spacing={2} justifyContent={"space-between"} padding={2}>
          <Grid item xs={12} lg={8}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              {description}
            </Typography>
            {allowList === true && toShowListSelector && <AddToListComponent
                    validateFunction={checkFunc}
                    helperText={helperText}
                    guideText={guideText}
                    items={items}
                    setItems={setItems}
                  />}
          </Grid>
          <Grid item xs={12} lg={3} justifyContent={"center"} alignItems={"center"} >
              <Stack justifyContent={"center"} alignItems={"center"} style={{height:"100%"}}>
                {booleanOption !== undefined && <Switch checked={isBooleanOptionEnabled} onChange={handleBooleanOptionChange} />}

                {stringOption !== undefined && <TextField
                      fullWidth
                      variant="outlined"
                      label={label}
                      value={stringOptionValue}
                      onChange={handleStringOptionChange}
                      helperText={error ? helperText : null}
                      error={error}
                    />}

                  

                  {select === true && (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectValue}
                      size="small"
                      //label="Age"
                      onChange={handleSelectChange}  // You need to provide a valid onChange handler here
                    >
                      {selectFields.map((element, index) => (
                        <MenuItem key={index} value={index}>
                          {element}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                    
                <Button
                  //variant="filledTonal"
                  variant="outlined"
                  color="primary"
                  onClick={handleUpdateClick}
                  sx={ { borderRadius: 28 } }
                  style={{ marginTop: '10px', maxWidth: "100px"}}
                >
                  <Typography>Update</Typography>
                </Button>
              </Stack>
          </Grid>
        </Grid>
    </Paper>
  );
};

export default SettingsCard;
