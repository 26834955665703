import React from 'react'
import LoadingCircle from '../dashboardComponents/LoadingCircle';
import {
  Card,
  CardHeader,
} from "@mui/material";
import UserInfoPaper from "./SettingComponnent/UserInfoPaper";



const ParseIfNotNull = (obj) =>
{
  if (obj !== null && obj !== undefined)
  {
    return JSON.parse(obj.data);
  }

  return null;
}

// "freeGigaStorage":10,"freeGigaUsage":10,"planPrice":20,"pricePerGigaBendwidth":0.05,"pricePerGigaStorage":0.025,"id":"20db9ccd447f48d49d4f62f5e7ddb7e7","firstName":"Meir","lastName":"asfasf","capabilities":"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16","email":"aaa@aaa.com","isActive":true,"restriction":null,"encryption":"False"
// firstName, lastName, email
// 3 options:
//  Loading state, have data, don't have data
const UserInformation = ({userInformation, isLoading}) => {
    const labels = ['First Name', 'Last Name', 'Email'];
    const values = [];
    var information = null;
    const obj = ParseIfNotNull(userInformation);
    if (isLoading === false && obj !== null)
    {
      
      values.push(obj.firstName);
      values.push(obj.lastName);
      values.push(obj.email);
      information = (<UserInfoPaper labels={labels} values={values} />);
    }

    

  return (
    <Card
      style={{
        height: "100%",
        //position: "relative",
        //backgroundColor: "#E3F2FD",
        //position: 'relative'
      }}
    >
      <CardHeader
        title="Account"
        style={{ textAlign: "center", color:"#757575" }}
      />
        {isLoading === true ? <LoadingCircle/> : information}
    </Card>
  )
}

export default UserInformation;
